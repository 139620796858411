import React, { useEffect, useState } from "react";
import { css } from '@emotion/core';
import Layout from '../components/layout';
import { clearCart } from "../common/cart";

export default () => {
  let [paymentOption, setPaymentOption] = useState('');
  // if (typeof window !== 'undefined') {
  //   paymentOption = new URLSearchParams(window.location.search).get('payment');
  // }

  clearCart();

  useEffect(() => {
    setPaymentOption(new URLSearchParams(window.location.search).get('payment'));
  }, []);

  return (
    <Layout>
      <section css={css`
        margin: 0 0 5rem 0;
      `}>
        <h1>Success</h1>

        <p id="cod" className={paymentOption === 'COD' ? '' : 'hide'}>
          Your order is placed successfully! <br />
          Email or WhatsApp your name, and mobile No. to confirm the order.
        </p>

        <div id="bank-transfer" className={paymentOption === 'Bank Transfer' ? '' : 'hide'}>
          <p>
            After transferring the amount, Email or WhatsApp us your name, and mobile No.
          </p>

          <strong>Meezan Bank</strong> <br />
          Fawad Hassan/Samar Majeed <br />
          Account No: 0313-0108445175 <br />
          IBAN: PK09MEZN0003130108445175
        </div>

        <div id="easypaisa" className={paymentOption === 'Easypaisa' ? '' : 'hide'}>
          <p>
            After transferring the amount, Email or WhatsApp us your name, and mobile No.
          </p>

          <strong>Easypaisa Account</strong> <br />
          Fawad Hassan <br />
          0333-5800074
        </div>
      </section>
    </Layout>
  );
};
